document.addEventListener('DOMContentLoaded', () => {
  /* Menu */
  const header = document.querySelector('.navbar')
  const body = document.querySelector('body')

  if (header) {
    const mobileTrigger = header.querySelector('.menu-toggle')
    //Open menu

    mobileTrigger.addEventListener('click', function () {
      if (!header.classList.contains('js-active')) {
        header.classList.add('js-active')
        body.classList.add('js-active')
      } else {
        header.classList.remove('js-active')
        body.classList.remove('js-active')
      }
    })
  }
})
